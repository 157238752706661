import * as React from 'react';
import loadable from '@loadable/component';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { sectionList, FormTypes } from '../../constants/queryFragments';
import { graphql } from 'gatsby';
import Cookies from 'universal-cookie';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import GlobalStyles from '../../styles/globalStyles';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import LanguageModal from '../../components/languageModal/languageModal';
import { Helmet } from 'react-helmet';
import {
  addTrailingSlash,
  isValidURLLanguage,
  validNodeLocale,
} from '../../utils';
const BlogPageTemplate = ({ data, location }) => {
  const cookies = new Cookies();
  const [loaded, setLoaded] = React.useState(false);

  const date = new Date();
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const updateAndSetLanguageCookie = (language) => {
    if (language && cookies.get('language') !== language) {
      cookies.set('language', language, {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return language;
    }
  };

  const selectedRegion = cookies.get('region')?.toUpperCase()
    ? cookies.get('region')?.toUpperCase().toUpperCase()
    : 'NB';

  React.useLayoutEffect(() => {
    setLoaded(true);
    AOS.init();
    AOS.refresh();
  }, []);

  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);

  const selectedLanguage =
    isValidURLLanguage(urlLanguage) &&
    validNodeLocale(urlLanguage) &&
    cookies.get('language') !== validNodeLocale(urlLanguage)
      ? updateAndSetLanguageCookie(validNodeLocale(urlLanguage))
      : cookies.get('language')
      ? cookies.get('language')
      : 'en-US';

  const languageFilteredData =
    data?.allContentfulUltramarCommercial?.edges?.find(
      (edge) => edge?.node?.node_locale === selectedLanguage
    );

  const englishSlug = data?.allContentfulUltramarCommercial?.edges?.find(
    (edge) => edge?.node?.node_locale === 'en-US'
  )?.node?.slug;

  const frenchSlug = data?.allContentfulUltramarCommercial?.edges?.find(
    (edge) => edge?.node?.node_locale === 'fr-CA'
  )?.node?.slug;

  const sectionsArray = languageFilteredData?.node?.sections?.find(
    (region) => region.abbreviation === selectedRegion
  )?.sections;

  return !loaded ? (
    <>
      <Helmet>
        <title>
          {languageFilteredData?.node?.title
            ? languageFilteredData?.node?.title + ' | Ultramar'
            : 'Energy Solutions for Residential and Businesses | Ultramar'}
        </title>
        {englishSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + englishSlug)}
            hreflang="en-ca"
          ></link>
        )}
        {frenchSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + frenchSlug)}
            hreflang="fr-ca"
          ></link>
        )}
        {languageFilteredData?.node?.metadata && (
          <meta
            name="description"
            content={languageFilteredData?.node?.metadata}
          ></meta>
        )}
      </Helmet>
      <GlobalStyles />
      <Header englishSlug={englishSlug} frenchSlug={frenchSlug} />
      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (
              section?.type &&
              sectionList?.includes(section?.type) &&
              !FormTypes?.includes(section?.type)
            ) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </>
      <BackToTopScroll />
      <Footer />
    </>
  ) : (
    <>
      <Helmet>
        <title>
          {languageFilteredData?.node?.title
            ? languageFilteredData?.node?.title + ' | Ultramar'
            : 'Energy Solutions for Residential and Businesses | Ultramar'}
        </title>
        {englishSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + englishSlug)}
            hreflang="en-ca"
          ></link>
        )}
        {frenchSlug && (
          <link
            rel="alternate"
            href={addTrailingSlash(process.env.GATSBY_DOMAIN_URL + frenchSlug)}
            hreflang="fr-ca"
          ></link>
        )}
      </Helmet>
      <GlobalStyles />
      <LanguageModal />
      <Header englishSlug={englishSlug} frenchSlug={frenchSlug} />
      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (section?.type && sectionList.includes(section?.type)) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </>
      <BackToTopScroll />
      <Footer />
    </>
  );
};

export default BlogPageTemplate;

export const pageQuery = graphql`
  query allBlogPagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulTextWithImage: Boolean!
    $contentfulContentList: Boolean!
  ) {
    allContentfulUltramarCommercial(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          metadata
          title
          node_locale
          sections {
            ... on ContentfulRegions {
              id
              title
              abbreviation
              sections {
                ...TextWithImage @include(if: $contentfulTextWithImage)
                ...PageContent @include(if: $contentfulContent)
                ...ContentList @include(if: $contentfulContentList)
              }
            }
          }
        }
      }
    }
  }
`;
